<template>
  <!-- {{settions}} -->
  <div class="home_page">
    <!-- 訂閱卡 -->
    <template v-if="showSettions">
      <!-- 促銷 -->
      <div v-if="settions.promotions?.length > 0" class="card card-block card-stretch card-height">
        <div class="card-header d-flex justify-content-between">
          <div class="header-title">
            <h5 class="card-title">{{ $t('label.promotion') }}</h5>
          </div>
        </div>
        <div v-for="(item, index) in settions.promotions" :key="index" class="card-body">
          <div class="d-grid">
            <button :disabled="onLoading" @click="
                openDialog(
                  item,
                  5,
                  `${$t('促銷')}`,
                  `${$t('訂閱')} / ${$t('促銷')} ${item.promotions_month} ${$t('個月')} (${$t('省下')} ${item.promotions_discount})`,
                  item.promotions_amount,
                  previewUser,
                  (options = 'single')
                )
              " type="button" class="btn btn-primary rounded-pill">
              <div class="d-flex justify-content-between">
                <span>{{ $t('訂閱') }} / {{ $t('促銷') }} {{ item.promotions_month }} {{ $t('個月') }} ({{ $t('省下') }}{{
                    item.promotions_discount
                  }})</span>
                <span>{{ item.promotions_amount }}</span>
              </div>
            </button>
            <div class="text-secondary">{{ item.program_desc }}</div>
          </div>
        </div>
      </div>
      <!-- 綑綁 -->
      <div v-if="settions.subscription_bundle?.length > 0" class="card card-block card-stretch card-height">
        <div class="card-header d-flex justify-content-between">
          <div class="header-title">
            <h5 class="card-title">{{ $t('label.subscriptionBundle') }}</h5>
          </div>
        </div>
        <div v-for="(item, index) in settions.subscription_bundle" :key="index" class="card-body">
          <div class="d-grid">
            <button :disabled="onLoading" @click="
                openDialog(
                  item,
                  6,
                  `${$t('訂閱綑綁')}`,
                  `${$t('訂閱')} / ${$t('促銷')} ${item.subscription_bundle_duration} ${$t('個月')} (${$t('省下')} ${item.subscription_bundle_discount})`,
                  item.subscription_bundle_amount,
                  previewUser,
                  (options = 'single')
                )
              " type="button" class="btn btn-primary rounded-pill">
              <div class="d-flex justify-content-between">
                <span>{{ $t('訂閱') }} / {{ item.subscription_bundle_duration }} {{ $t('個月') }} ({{ $t('省下') }}{{
                    item.subscription_bundle_discount
                  }})</span>
                <span> {{ item.subscription_bundle_amount }}</span>
              </div>
            </button>
            <div class="text-secondary">{{ item.program_desc }}</div>
          </div>
        </div>
      </div>
      <!-- 方案 -->
      <div v-if="settions.program?.length > 0" class="card card-block card-stretch card-height">
        <div class="card-header d-flex justify-content-between">
          <div class="header-title">
            <h5 class="card-title">{{ $t('label.subscription') }}</h5>
          </div>
        </div>
        <div v-for="(item, index) in settions.program" :key="index" class="card-body">
          <div class="d-grid">
            <button :disabled="onLoading || item.is_btn_enabled === 0" @click="
                openDialog(
                  item,
                  4,
                  `${$t('訂閱')}`,
                  `${$t('訂閱')} / ${$t('每月')}．${item.program_title}`,
                  item.program_price,
                  previewUser,
                  (options = 'single')
                )
              " type="button" class="btn btn-primary rounded-pill subscribe_btn">
              <div class="d-flex justify-content-between">
                <span> {{ $t('訂閱') }} / {{ $t('每月') }}．{{ item.program_title }}</span>
                <span v-if="item.is_btn_enabled === 1">{{ item.program_price }}</span>
                <span v-else>將於 {{ item.exp_datetime }} 到期</span>
              </div>
            </button>
            <!-- <div class="text-secondary">{{ item.program_desc }}</div> -->
          </div>
        </div>
      </div>
    </template>
    <!-- 訂閱卡! -->

    <!-- 個人頁單篇解鎖 每一筆貼文 -->
    <div v-if="singleLockNew" class="row align-items-center">
      <div v-for="(item, index) in Cpost" :key="index" class="col-sm-6">
        <div class="card card-block card-stretch card-height">
          <div class="card-body">
            <div class="user-post-data mb-3">
              <div class="d-flex justify-content-between">
                <div class="me-2">
                  <img class="avatar-60 rounded-circle cursor_pointer" :src="item.avatar" @click="goDetailPage(item.user_account)" style="width: 30px;height: 30px;">
                  <!-- <el-image class="avatar-60 rounded-circle" :src="item.avatar" lazy>
                    
                  </el-image> -->
                </div>
                <div class="w-100">
                  <div class="d-flex justify-content-between">
                    <div class="">
                      <h5 class="mb-0 d-inline-block account_info" @click="goDetailPage(item.user_account)">{{ item.user_name }}</h5>
                    </div>
                    <div class="card-post-toolbar d-flex justify-content-end">
                      <div class="pe-2 mb-0 d-inline-block">
                        <el-tag size="mini" effect="plain" round>{{
                          item.post_type_name
                        }}<span v-if="item.program_title !== ''">: {{ item.program_title }}</span></el-tag>
                      </div>
                      <div class="dropdown">
                        <span class="dropdown-toggle" id="postdata-5" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                          <i class="ri-more-fill"></i>
                        </span>
                        <div class="dropdown-menu m-0 p-0" aria-labelledby="postdata-5">
                          <a class="dropdown-item p-2" href="javascript:void(0)" @click="onCopyText(item)">
                            <div class="d-flex align-items-center">
                              <i class="ri-share-box-fill h4"></i>
                              <div class="data ms-2">
                                <p class="mb-0">{{ $t('label.copyLink') }}</p>
                              </div>
                            </div>
                          </a>
    
                          <a v-if="user.account === item.user_account" class="dropdown-item p-2" href="javascript:void(0)" @click="ePost(index,item)">
                            <div class="d-flex align-items-center">
                              <i class="ri-ball-pen-line h4"></i>
                              <div class="data ms-2">
                                <p class="mb-0">{{ $t('編輯貼文') }}</p>
                              </div>
                            </div>
                          </a>
                          <a v-if="user.account === item.user_account" class="dropdown-item p-2" href="javascript:void(0)" @click="dPost(index,item)">
                            <div class="d-flex align-items-center">
                              <i class="ri-close-circle-line h4"></i>
                              <div class="data ms-2">
                                <p class="mb-0">{{ $t('刪除貼文') }}</p>
                              </div>
                            </div>
                          </a>
                          <a class="dropdown-item p-2" href="javascript:void(0)" @click="onActionDialog(item)">
                            <div class="d-flex align-items-center">
                              <i class="ri-alert-line h4"></i>
                              <div class="data ms-2">
                                <p class="mb-0">{{ $t('label.report') }}</p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <!-- 鎖定 -->
            <template v-if="item.is_post_lock">
              <template v-if="item.post_donate === ''">
                <div class="user-post">
                  <div class="p-2">
                    <div class="lock-icon d-flex justify-content-center">
                      <i class="ri-lock-line"></i>
                    </div>
                    <div class="subscript-block p-2">
                      <div class="content-icon ps-2 pt-2">
                        <i v-if="item.post_media_video.length >= 1" class="ri-vidicon-fill"></i>
                        <i v-if="item.post_media_photo.length >= 1" class="ri-image-fill ps-2"></i>
                      </div>
                      <div class="content-btn d-grid p-2">
                        <button type="submit" class="btn btn-primary rounded-pill" @click="
                            item.post_donate === '' ?
                            openDialog(
                              item,
                              4,
                              `${$t('訂閱綑綁')}`,
                              `${$t('訂閱')} / ${$t('促銷')} ${item.subscription_bundle_duration} ${$t('個月')}(${$t('省下')} ${item.subscription_bundle_discount})`,
                              item.subscription_bundle_amount,
                              previewUser,
                              (options = 'multiple')
                            )
                            :
                            openDialog(
                              item,
                              'post_untie',
                              `${$t('label.single_unlock')}`,
                              `${$t('label.single_unlock')}`,
                              item.post_donate,
                              previewUser,
                              (options = 'multiple')
                            )
                          ">
                          {{ item.post_donate === '' ? $t('label.subscribeToSeeUserPosts') : $t('永久解鎖單篇文章') }} 
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <a href="javascript:void(0);">
                <el-carousel v-viewer indicator-position="none" :autoplay="false" :arrow="item.post_banner.length > 1 ? 'always' : 'never'" height="200px">
                  <el-carousel-item v-for="(media, index2) in [item.post_banner]" :key="index2">
                    <div class="g-pointer-cursor">
                      <div class="black">
                        <div class="image-container" :style="`background-image: url(${media.img.media_url})`">
                          <img ref="image" :src="media.img.media_url" class="clear-image" :height="imageHeight" />
                          <div :style="`background-image: url(${media.square.media_square_url_webp})`" class="blurry-image" :width="imgH[index2]"></div>
                          <!-- <img :src="media.media_square_url_webp" class="blurry-image" :width="imgH[index2]" /> -->
                          <div class="unlock_post d-flex justify-content-center">
                            <div class="lock-icon d-flex justify-content-center">
                              <i class="ri-lock-line" style="font-size: 20px;"></i>
                            </div>
                            <div class="ms-3" @click="
                              openDialog(
                                item,
                                'post_untie',
                                `${$t('label.single_unlock')}`,
                                `${$t('label.single_unlock')}`,
                                item.post_donate,
                                previewUser,
                                (options = 'multiple')
                              )
                            "
                            >
                              {{ $t('永久解鎖單篇文章') }} {{ item.post_donate }}
                            </div>
                          </div>
                          <div class="photo_num d-flex justify-content-center">
                            <i class="ri-image-2-line"></i>
                            <div class="ms-1">{{ item.media_count }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </a>
              </template>
            </template>
    
            <!-- 已解鎖 -->
            <template v-else>
              <!-- 圖片 -->
              <div class="user-post" v-show="item.post_media_photo.length + item.post_media_video.length >= 1">
                <a href="javascript:void(0);">
                  <el-carousel v-viewer indicator-position="none" :autoplay="false" :arrow="item.post_media_photo.length + item.post_media_video.length > 1 ? 'always' : 'never'" height="200px">
                    <el-carousel-item v-for="(media, index2) in item.post_media_photo" :key="index2">
                      <div class="g-pointer-cursor">
                        <div class="black">
                          <div class="image-container" :style="`background-image: url(${media.media_url})`">
                            <img ref="image" :src="media.media_url" class="clear-image" :height="imageHeight" />
                            <div :style="`background-image: url(${media.media_square_url_webp})`" class="blurry-image" :width="imgH[index2]"></div>
                            <!-- <img :src="media.media_square_url_webp" class="blurry-image" :width="imgH[index2]" /> -->
                          </div>
                        </div>
                      </div>
                    </el-carousel-item>
                    <el-carousel-item v-for="(media, index2) in item.post_media_video" :key="index2">
                      <video-player
                        :ref="`videoPlayer_${index}_${index2}`"
                        :options="videoOptions(media)"
                        :setIndex="`${index}_${index2}`"
                        @setVideowWatermark="setVideowWatermark"
                        style="width: 100%;height: 100% !important;"
                      />
    
                      <!-- 自訂全螢幕按鈕 -->
                      <div :ref="`fullscreen_${index}_${index2}`" class="full_screen" data-bs-toggle="modal" :data-bs-target="`#full_screen_modal_${index}_${index2}`" @click="setFullScreenInfo(media, user.user_name, index, index2)">
                        <el-icon color="#fff" :size="20"><FullScreen /></el-icon>
                      </div>
                      <i :ref="`videoPlayerI_${index}_${index2}`" style="top: 0%; left: 43%;" class="video-user-name">{{ user.user_name}}</i>
                    </el-carousel-item>
                  </el-carousel>
                </a>
              </div>
            </template>
  
            <div class="mt-3">
              <p v-html="item.post_content.replaceAll('\n', '<br>')" :ref="`more_${index}`" class="post_content cursor_pointer" :class="item.post_content.length > 200 ? 'more' : ''" @click="$router.push(`/post/${item.post_id}`)">
                
              </p>
            </div>

            <div class="comment-area mt-3">
              <div class="d-flex justify-content-between align-items-center flex-wrap">
                <div class="like-block position-relative d-flex align-items-center">
                  <div class="d-flex align-items-center">
                    <button @click="postLike(index, item)" type="button" class="btn btn-circle-sm mb-1 me-1">
                      <i :class="`fa${
                          !item.is_user_likes ? 'r' : ''
                        } fa-heart me-0 text-primary`"></i>
                    </button>
                  </div>
                  <div class="total-comment-block">
                    <button type="button" class="btn btn-circle-sm mb-1 me-1">
                      <i class="far fa-comment me-0 text-primary"></i>
                    </button>
                  </div>
                  <div v-if="user.account !== item.user_account" class="total-comment-block">
                    <button @click="openTipDialog(item)" type="button" class="btn btn-circle-sm mb-1 me-1">
                      <i class="fas fa-hand-holding-usd fa-fw me-0 text-primary"></i>
                    </button>
                  </div>
                </div>
                <!-- 追蹤 follow -->
                <!-- :disabled="item.is_post_lock -->
                <div class="share-block d-flex align-items-center feather-icon mt-2 mt-md-0">
                  <button @click="postFollow(index, item)" type="button" class="btn btn-circle-sm mb-1 me-1">
                    <i :class="`fa${
                        !item.is_user_follow ? 'r' : ''
                      } fa-bookmark fa-fw me-0 text-primary`"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 每一筆貼文 -->
    <template v-else>
      <div v-for="(item, index) in Cpost" :key="index" class="card card-block card-stretch card-height col-sm-12">
        <div class="card-body">
          <div class="user-post-data">
            <div class="d-flex justify-content-between">
              <div class="me-3">
                <img class="avatar-60 rounded-circle cursor_pointer" :src="item.avatar" @click="goDetailPage(item.user_account)">
                <!-- <el-image class="avatar-60 rounded-circle" :src="item.avatar" lazy>
                  
                </el-image> -->
              </div>
              <div class="w-100">
                <div class="d-flex justify-content-between">
                  <div class="">
                    <h5 class="mb-0 d-inline-block account_info" @click="goDetailPage(item.user_account)">{{ item.user_name }}</h5>
  
                    <div class="mb-0 text-gray float-right">
                      <router-link class="link-gray me-1" :to="`/${item.user_account}`">
                        {{ item.user_account }}
                      </router-link>
  
                      <el-tooltip class="item" effect="dark" :content="`${item.created_date} ${item.created_time}`" placement="bottom">
                        <span class="d-inline-block mb-0 text-primary">
                          ·
                          {{
                            formatDate(
                              `${item.created_date} ${item.created_time}`
                            )
                          }}
                        </span>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="card-post-toolbar d-flex justify-content-end">
                    <div class="pe-2 mb-0 d-inline-block">
                      <el-tag size="mini" effect="plain" round>{{
                        item.post_type_name
                      }}<span v-if="item.program_title !== ''">: {{ item.program_title }}</span></el-tag>
                    </div>
                    <div class="dropdown">
                      <span class="dropdown-toggle" id="postdata-5" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                        <i class="ri-more-fill"></i>
                      </span>
                      <div class="dropdown-menu m-0 p-0" aria-labelledby="postdata-5">
                        <a class="dropdown-item p-2" href="javascript:void(0)" @click="onCopyText(item)">
                          <div class="d-flex align-items-center">
                            <i class="ri-share-box-fill h4"></i>
                            <div class="data ms-2">
                              <p class="mb-0">{{ $t('label.copyLink') }}</p>
                            </div>
                          </div>
                        </a>
  
                        <a v-if="user.account === item.user_account" class="dropdown-item p-2" href="javascript:void(0)" @click="ePost(index,item)">
                          <div class="d-flex align-items-center">
                            <i class="ri-ball-pen-line h4"></i>
                            <div class="data ms-2">
                              <p class="mb-0">{{ $t('編輯貼文') }}</p>
                            </div>
                          </div>
                        </a>
                        <a v-if="user.account === item.user_account" class="dropdown-item p-2" href="javascript:void(0)" @click="dPost(index,item)">
                          <div class="d-flex align-items-center">
                            <i class="ri-close-circle-line h4"></i>
                            <div class="data ms-2">
                              <p class="mb-0">{{ $t('刪除貼文') }}</p>
                            </div>
                          </div>
                        </a>
                        <a class="dropdown-item p-2" href="javascript:void(0)" @click="onActionDialog(item)">
                          <div class="d-flex align-items-center">
                            <i class="ri-alert-line h4"></i>
                            <div class="data ms-2">
                              <p class="mb-0">{{ $t('label.report') }}</p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <p v-html="item.post_content.replaceAll('\n', '<br>')" :ref="`more_${index}`" :class="item.post_content.length > 200 ? 'more' : ''">
              
            </p>
          </div>
          <div class="mt-3">
            <div v-if="item.post_content.length > 200">
              <a :ref="`more_link_${index}`" href="javascript:void(0)">
                <p @click="more(index)">{{ $t('label.showMore') }}</p>
              </a>
              <a :ref="`few_link_${index}`" href="javascript:void(0)" style="display: none">
                <p @click="few(index)">{{ $t('label.showLess') }}</p>
              </a>
            </div>
          </div>
          <!-- white-space: pre-line; overflow: hidden; max-height: none; height: 224px;  -->
          <!-- 鎖定 -->
          <template v-if="item.is_post_lock">
            <template v-if="item.post_donate === ''">
              <div class="user-post">
                <div class="p-2">
                  <div class="lock-icon d-flex justify-content-center">
                    <i class="ri-lock-line"></i>
                  </div>
                  <div class="subscript-block p-2">
                    <div class="content-icon ps-2 pt-2">
                      <i v-if="item.post_media_video.length >= 1" class="ri-vidicon-fill"></i>
                      <i v-if="item.post_media_photo.length >= 1" class="ri-image-fill ps-2"></i>
                    </div>
                    <div class="content-btn d-grid p-2">
                      <button type="submit" class="btn btn-primary rounded-pill" @click="
                          item.post_donate === '' ?
                          openDialog(
                            item,
                            4,
                            `${$t('訂閱綑綁')}`,
                            `${$t('訂閱')} / ${$t('促銷')} ${item.subscription_bundle_duration} ${$t('個月')}(${$t('省下')} ${item.subscription_bundle_discount})`,
                            item.subscription_bundle_amount,
                            previewUser,
                            (options = 'multiple')
                          )
                          :
                          openDialog(
                            item,
                            'post_untie',
                            `${$t('label.single_unlock')}`,
                            `${$t('label.single_unlock')}`,
                            item.post_donate,
                            previewUser,
                            (options = 'multiple')
                          )
                        ">
                        {{ item.post_donate === '' ? $t('label.subscribeToSeeUserPosts') : $t('永久解鎖單篇文章') }} 
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <a href="javascript:void(0);">
              <el-carousel v-viewer indicator-position="outside" :autoplay="false" :arrow="item.post_banner.length > 1 ? 'always' : 'never'" :height="`${windowWidth >= 600 ? '600px' : '414px'}`">
                <el-carousel-item v-for="(media, index2) in [item.post_banner]" :key="index2">
                  <div class="g-pointer-cursor">
                    <div class="black">
                      <div class="image-container" :style="`background-image: url(${media.img.media_url})`">
                        <img ref="image" :src="media.img.media_url" class="clear-image" :height="imageHeight" />
                        <div :style="`background-image: url(${media.square.media_square_url_webp})`" class="blurry-image" :width="imgH[index2]"></div>
                        <!-- <img :src="media.media_square_url_webp" class="blurry-image" :width="imgH[index2]" /> -->
                        <div class="unlock_post d-flex justify-content-center">
                          <div class="lock-icon d-flex justify-content-center">
                            <i class="ri-lock-line" style="font-size: 20px;"></i>
                          </div>
                          <div class="ms-3" @click="
                            openDialog(
                              item,
                              'post_untie',
                              `${$t('label.single_unlock')}`,
                              `${$t('label.single_unlock')}`,
                              item.post_donate,
                              previewUser,
                              (options = 'multiple')
                            )
                          "
                          >
                            {{ $t('永久解鎖單篇文章') }} {{ item.post_donate }}
                          </div>
                        </div>
                        <div class="photo_num d-flex justify-content-center">
                          <i class="ri-image-2-line"></i>
                          <div class="ms-1">{{ item.media_count }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </a>
            </template>
          </template>
  
          <!-- 已解鎖 -->
          <template v-else>
            <!-- 圖片 -->
            <div class="user-post" v-show="item.post_media_photo.length + item.post_media_video.length >= 1">
              <a href="javascript:void(0);">
                <el-carousel v-viewer indicator-position="outside" :autoplay="false" :arrow="item.post_media_photo.length + item.post_media_video.length > 1 ? 'always' : 'never'" :height="`${windowWidth >= 600 ? '600px' : '414px'}`">
                  <el-carousel-item v-for="(media, index2) in item.post_media_photo" :key="index2">
                    <div class="g-pointer-cursor">
                      <div class="black">
                        <div class="image-container" :style="`background-image: url(${media.media_url})`">
                          <img ref="image" :src="media.media_url" class="clear-image" :height="imageHeight" />
                          <div :style="`background-image: url(${media.media_square_url_webp})`" class="blurry-image" :width="imgH[index2]"></div>
                          <!-- <img :src="media.media_square_url_webp" class="blurry-image" :width="imgH[index2]" /> -->
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                  <el-carousel-item v-for="(media, index2) in item.post_media_video" :key="index2">
                    <video-player
                      :ref="`videoPlayer_${index}_${index2}`"
                      :options="videoOptions(media)"
                      :setIndex="`${index}_${index2}`"
                      @setVideowWatermark="setVideowWatermark"
                      style="width: 100%;height: 100% !important;"
                    />
  
                    <!-- 自訂全螢幕按鈕 -->
                    <div :ref="`fullscreen_${index}_${index2}`" class="full_screen" data-bs-toggle="modal" :data-bs-target="`#full_screen_modal_${index}_${index2}`" @click="setFullScreenInfo(media, user.user_name, index, index2)">
                      <el-icon color="#fff" :size="20"><FullScreen /></el-icon>
                    </div>
                    <i :ref="`videoPlayerI_${index}_${index2}`" style="top: 0%; left: 43%;" class="video-user-name">{{ user.user_name}}</i>
                  </el-carousel-item>
                </el-carousel>
              </a>
            </div>
            <!-- 影片 -->
            <!-- <div class="user-post" v-show="item.post_media_video.length >= 1" style="display: none;">
              <el-carousel :autoplay="false" :arrow="item.post_media_video.length > 1 ? 'always' : 'never'">
                <el-carousel-item v-for="(media, index2) in item.post_media_video" :key="index2">
                  <videoPlay
                    playsinline
                    x5-playsinline="true"
                    webkit-playsinline="true"
                    style="z-index: 2;width: 100%;height: 100%;display: block;"
                    :ref="`videoPlayer_${index}_${index2}`"
                    v-bind="getOption(media)"
                    @play="handleVideoClick(`${index}_${index2}`)"
                    @loadedmetadata="startPlayback(`videoPlayer_${index}_${index2}`)"
                  />
                  <video @play="handleVideoClick(`${index}_${index2}`)" @click="handleVideoClick(`videoPlayer_${index}_${index2}`)" preload="none" controls controlslist="nodownload" webkit-playsinline="true" playsinline="true" style="z-index: 2"
                    :poster="media.media_url" :ref="`videoPlayer_${index}_${index2}`">
                    <source :src="toBlob(media.media_video_url)" type="video/mp4">
                  </video>
  
                  <video-player
                    :ref="`videoPlayer_${index}_${index2}`"
                    :options="videoOptions(media)"
                    :setIndex="`${index}_${index2}`"
                    @setVideowWatermark="setVideowWatermark"
                    style="width: 100%;height: 100%;"
                  />
  
                  <div class="full_screen" data-bs-toggle="modal" :data-bs-target="`#full_screen_modal_${index}_${index2}`" @click="setFullScreenInfo(media, user.user_name, index, index2)">
                    <el-icon color="#fff" :size="20"><FullScreen /></el-icon>
                  </div>
                  <i :ref="`videoPlayerI_${index}_${index2}`" style="top: 0%; left: 43%;" class="video-user-name">{{ user.user_name}}</i>
                </el-carousel-item>
              </el-carousel>
  
            </div> -->
          </template>
  
          <!-- 籌資目標 -->
          <div v-if="item.post_target !== ''" class="mt-3 d-flex justify-content-between align-items-center">
            <div class="current_money d-flex align-items-center">
              <i class="ri-money-dollar-circle-fill" style="color: #50b5ff;font-size: 20px;"></i>
              <div class="ms-2">{{ item.current_post_target }}</div>
            </div>
            <div class="total">{{ item.post_target }}</div>
          </div>
  
          <div class="comment-area mt-1">
            <div class="d-flex justify-content-between align-items-center flex-wrap">
              <div class="like-block position-relative d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <button @click="postLike(index, item)" type="button" class="btn btn-circle-sm mb-1 me-1">
                    <i :class="`fa${
                        !item.is_user_likes ? 'r' : ''
                      } fa-heart me-0 text-primary`"></i>
                  </button>
                </div>
                <div class="total-comment-block">
                  <button @click="showComment(index)" type="button" class="btn btn-circle-sm mb-1 me-1">
                    <i class="far fa-comment me-0 text-primary"></i>
                  </button>
                </div>
                <div v-if="user.account !== item.user_account" class="total-comment-block">
                  <button @click="openTipDialog(item)" type="button" class="btn btn-circle-sm mb-1 me-1">
                    <i class="fas fa-hand-holding-usd fa-fw me-0 text-primary"></i>
                  </button>
                </div>
              </div>
              <!-- 追蹤 follow -->
              <!-- :disabled="item.is_post_lock -->
              <div class="share-block d-flex align-items-center feather-icon mt-2 mt-md-0">
                <button @click="postFollow(index, item)" type="button" class="btn btn-circle-sm mb-1 me-1">
                  <i :class="`fa${
                      !item.is_user_follow ? 'r' : ''
                    } fa-bookmark fa-fw me-0 text-primary`"></i>
                </button>
              </div>
            </div>
            <hr />
          </div>
          <div class="comment-area mt-1">
            <ul v-if="item.post_comment_show" class="post-comments list-inline p-0 m-0">
              <li v-for="(r, index3) in item.post_comment" :key="index3" class="mb-2">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="user-img">
                      <!-- <img :src="r.avatar" alt="userimg" class="avatar-35 rounded-circle img-fluid"> -->
                      <img class="avatar-35 rounded-circle img-fluid cursor_pointer" :src="r.avatar"  @click="goDetailPage(r.user_account)">
                        <!-- <template #placeholder>
                          <div v-loading="true"></div>
                        </template>
                      </el-image> -->
                    </div>
                    <div class="comment-data-block ms-3">
                      <h6 class="account_info" @click="goDetailPage(r.user_account)">{{ r.user_name }}</h6>
                      <p class="mb-0" v-html="textToTagFormat(r.post_comment)"></p>
                      <div class="d-flex flex-wrap align-items-center comment-activity">
                        <span class="pe-1">
                          {{ r.created_time }}, {{ r.created_date }}
                        </span>
                        <a @click="rep(index, index3, r)" href="javascript:void(0);">{{ $t('回覆') }}</a>
                        <a v-if="user.account === r.user_account" @click="del(index, index3)" href="javascript:void(0);">{{ $t('刪除') }}</a>
                      </div>
                    </div>
                  </div>
                  <div class="float-end">
                    <a @click="commentLike(index, index3)" href="javascript:void(0)">
                      <i :class="`fa${
                          !r.is_user_likes ? 'r' : ''
                        } fa-heart me-0 text-primary`"></i>
                    </a>
                  </div>
                </div>
              </li>
            </ul>
            <form v-if="item.post_comment_show" class="comment-text d-flex align-items-center mt-3" action="javascript:void(0);">
              <input v-model="commentMessage[index]" type="text" class="form-control rounded" :placeholder="$t('label.enterYourComment')" />
              <div class="comment-attagement d-flex">
                <a @click="send(index, item)" href="javascript:void(0);">
                  <button type="button" class="btn btn-circle-sm mb-1 me-1">
                    <i class="far fa-paper-plane me-0 text-primary"></i>
                  </button>
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </template>
    <!-- 當沒有貼文時 -->
    <div v-if="!Cpost.length" class="card card-block card-stretch card-height">
      <div v-if="$route.name === 'social.subscription'" class="card-body text-center">
        {{ $t('label.thereAreCurrentlyNoSubscriptions') }}
      </div>
      <div v-else-if="$route.name === 'social.follow'" class="card-body text-center">
        {{ $t('label.thereAreCurrentlyNoPostsToFollow') }}
      </div>
      <div v-else class="card-body text-center">
        {{ $t('label.thereAreCurrentlyNoPosts') }}
      </div>
    </div>
    <!-- ！每一筆貼文 -->

    <!-- 檢舉 -->
    <el-dialog v-model="centerDialogVisible" :title="$t('label.report')" width="90%" center>
      <el-radio-group v-model="reportContent" class="ml-4">
        <el-radio :label="$t('label.iDontLikePosting')" size="large">{{
          $t('label.iDontLikePosting')
        }}</el-radio>
        <el-radio :label="$t('label.thisContentIsOffensiveOrViolatesTermsOfService')" size="large">
          {{ $t('label.thisContentIsOffensiveOrViolatesTermsOfService') }}
        </el-radio>
        <el-radio :label="$t('label.thisContentContainsStolenMaterial(DMCA)')" size="large">
          {{ $t('label.thisContentContainsStolenMaterial(DMCA)') }}</el-radio>
        <el-radio :label="$t('label.thisContentIsSpam')" size="large">{{
          $t('label.thisContentIsSpam')
        }}</el-radio>
        <el-radio :label="$t('label.reportAbuse')" size="large">{{
          $t('label.reportAbuse')
        }}</el-radio>
      </el-radio-group>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="onActionDialog()">{{
            $t('label.cancel')
          }}</el-button>
          <el-button :loading="reportOnloading" type="primary" @click="sendReport()" :disabled="!reportContent">
            {{ $t('label.report') }}
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 全螢幕顯示 -->
    <div v-if="showFullScreen" class="modal_wrap">
      <div class="modal-dialog modal-fullscreen-sm-down modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <div></div>
            <button :dismissable="true" class="btn btn-secondary" data-bs-dismiss="modal" aria-label="Close" @click="showFullScreen=false;">
              <i class="ri-close-fill"></i>
            </button>
          </div>
          <div class="modal-body">

            <!-- <videoPlay
              style="z-index: 2;position: unset;max-height: 80vh;"
              :ref="videoPlayer_full_screen"
              v-bind="getOptionAutoPlay(curSelectInfo.videoInfo)"
              @play="handleVideoFullScreenClick('videoPlayerI_full_screen')"
            /> -->

            <!-- <video @play="handleVideoFullScreenClick('videoPlayerI_full_screen')" autoplay controlslist="nodownload" playsinline controls style="z-index: 2;position: unset;max-height: 80vh;"
              :poster="curSelectInfo.videoInfo.media_url" :ref="'videoPlayer_full_screen'">
              <source :src="toBlob(curSelectInfo.videoInfo.media_video_url)" type="video/mp4">
            </video> -->

            <video-player
              ref="videoPlayer_full_screen"
              :options="videoFullOptions(curSelectInfo.videoInfo)"
              @click="handleVideoFullScreenClick('videoPlayerI_full_screen')"
              style="position: relative;z-index: 0;width: 100%;"
              class="video_wrap"
            />
            <i :ref="`videoPlayerI_full_screen`" style="top: 50%; left: 43%;" class="video-user-name">{{ curSelectInfo.username}}</i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <SubscriptionPay></SubscriptionPay>
  <SubscriptionUnlockPay></SubscriptionUnlockPay>
  <TipPay></TipPay>
</template>

<script>
import { FullScreen } from '@element-plus/icons-vue'
import { useCheckout } from '@/utils/useCheckout'
import { useDate } from '@/utils/useDate'
import { useBlob } from '@/utils/useBlob'
import { useConvert } from '@/utils/useConvert'
import 'swiper/swiper.scss'
// import videoPlay from 'vue3-video-play' // 引入组件
import { getCurrentInstance, ref, computed } from 'vue'
import { useStore } from 'vuex'
import SubscriptionPay from '@/components/custom/dialog/SubscriptionPay'
import SubscriptionUnlockPay from '@/components/custom/dialog/SubscriptionUnlockPay'
import TipPay from '@/components/custom/dialog/TipPay'
import { useI18n } from 'vue-i18n'
import VideoPlayer from './VideoPlayer.vue'
import {
  addReport,
  delP,
  addPostLike,
  addPostComment,
  delPost,
  addCommentLike,
  addFollow
} from '@/api/post'

export default {
  name: 'SocialPost',
  props: ['post', 'settions', 'showSettions', 'previewUser', 'singleLockNew'],
  components: {
    // Swiper,
    // SwiperSlide,
    // videoPlay,
    VideoPlayer,
    SubscriptionPay,
    SubscriptionUnlockPay,
    TipPay,
    FullScreen,
  },
  setup(props) {
    const { t } = useI18n({ useScope: 'global' })
    const store = useStore()
    const dialogPayVisible$ = computed(() => store.state.User.dialogPayVisible)
    const centerDialogVisible = ref(false)
    const reportContent = ref('')
    const reportPostId = ref('')
    const reportOnloading = ref(false)
    const user = computed(() => store.state.User.user)

    const { proxy } = getCurrentInstance()

    const {
      openTipDialog,
      openDialog,
      checkoutForm,
      action,
      onLoading
    } = useCheckout()
    const { formatDate } = useDate()
    const { toBlob } = useBlob()

    const { textToTagFormat } = useConvert()
    const Cpost = computed(() => props.post)
    const $copyText = proxy.$copyText
    const $toast = proxy.$toast

    const getOption = r => {
      return {
        width: '100%',
        height: '100%',
        color: '#818181',
        muted: true,
        webFullScreen: false,
        speedRate: ['0.75', '1.0', '1.25', '1.5', '2.0'],
        autoPlay: false,
        loop: false,
        mirror: false,
        ligthOff: false,
        volume: 0.3,
        control: true,
        title: '',
        src: r.media_video_url,
        // src:
        //   'https://sample-videos.com/video123/mp4/720/big_buck_bunny_720p_20mb.mp4',
        poster: r.media_url, //封面
        preload: 'auto',
        // playsinline: true,
        controlBtns: [
        "audioTrack",
          "quality",
          "speedRate",
          "volume",
          // "pip",
        ]
      }
    }

    const getOptionAutoPlay = r => {
      return {
        ...getOption(r),
        autoPlay: true,
      }
    }

    const onCopyText = r => {
      let postUrl = `${process.env.VUE_APP_FRONT_END_URL}post/${r.post_id}`
      $copyText(postUrl)
        .then(() => {
          // $toast.show('連結到貼子已被複製到剪貼板！')
          $toast.show(t('label.linkToPostHasBeenCopiedToClipboard'))
        })
        .catch(() => {
          $toast.show(`can't copy`)
        })
    }
    const onActionDialog = (item = {}) => {
      console.log(item)
      centerDialogVisible.value = !centerDialogVisible.value
      reportContent.value = ''
      reportPostId.value = item.post_id
    }

    const sendReport = () => {
      const d = {
        post_id: reportPostId.value,
        report_content: reportContent.value
      }
      reportOnloading.value = true
      addReport(d)
        .then(res => {
          console.log(res)
          $toast.show('舉報成功！')
          setTimeout(() => {
            centerDialogVisible.value = !centerDialogVisible.value
            reportOnloading.value = false
          }, 1500)
        })
        .catch(err => {
          $toast.show(err.response.data.message)
          console.log(err)
          reportOnloading.value = false
        })
    }
    const purchas = item => {
      console.log(item)
      store.dispatch('User/dialogItemAction', item)
      store.dispatch('User/dialogPayAction', !dialogPayVisible$.value)

      // store.dispatch('User/dialogCardConfirmAction', true)
    }
    return {
      purchas,
      $copyText,
      onCopyText,
      getOption,
      getOptionAutoPlay,
      textToTagFormat,
      openDialog,
      checkoutForm,
      action,
      onLoading,
      formatDate,
      centerDialogVisible,
      reportContent,
      onActionDialog,
      sendReport,
      reportPostId,
      reportOnloading,
      Cpost,
      openTipDialog,
      t,
      user,
      toBlob
    }
  },
  data() {
    return {
      showFullScreen: false,
      intervalId: null,
      imgH: [],
      contentHeight: window.innerHeight - window.innerHeight / 2,
      w: 0,
      options: {
        centeredSlides: false,
        loop: false,
        slidesPerView: 1,
        spaceBetween: 15,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        // And if we need scrollbar
        scrollbar: {
          el: '.swiper-scrollbar'
        }
      },
      commentMessage: [],
      curSelectInfo: {},
      windowWidth: window.innerWidth
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    playsinline() {

    },
    ePost(index, item) {
      this.$store.dispatch('User/dialogEditPostAction', true)
      this.$store.dispatch('User/dialogEditPostIdAction', item.post_id)
    },
    dPost(index, item) {
      delP(item)
        .then(res => {
          console.log(res)
          this.Cpost.splice(index, 1)
          this.$toast.show(this.$t('成功'))
        })
        .catch(err => {
          console.log(err)
        })
    },
    rep(index, index3, r) {
      this.Cpost[index].post_comment[index3]
      this.commentMessage[index] = ''
      if (r.user_account[0] === '@') {
        this.commentMessage[index] = `${r.user_account} `
      } else {
        this.commentMessage[index] = `@${r.user_account} `
      }
    },
    // index 貼文索引 index3留言索引
    del(index, index3) {
      const postCommentId = this.Cpost[index].post_comment[index3]
        .post_comment_id
      this.Cpost[index].post_comment.splice(index3, 1)
      delPost({
        post_comment_id: postCommentId
      })
        .then(res => {
          console.log(res)
        })
        .catch(err => {
          console.log(err)
        })
    },
    commentLike(index, index3) {
      const postCommentId = this.Cpost[index].post_comment[index3]
        .post_comment_id
      this.Cpost[index].post_comment[index3].is_user_likes = !this.Cpost[index]
        .post_comment[index3].is_user_likes
      addCommentLike({
        post_comment_id: postCommentId
      })
        .then(res => {
          console.log(res)
        })
        .catch(err => {
          console.log(err)
        })
    },
    postLike(index, item) {
      this.Cpost[index].is_user_likes = !this.Cpost[index].is_user_likes
      addPostLike(item)
        .then(res => {
          console.log(res)
        })
        .catch(err => {
          console.log(err)
        })
    },

    // index 貼文索引
    send(index, item) {
      const d = {
        post_comment: this.commentMessage[index],
        post_id: item.post_id
      }
      addPostComment(d)
        .then(res => {
          console.log(res)
          this.commentMessage[index] = ''
          this.Cpost[index].post_comment.push(res.data)
        })
        .catch(err => {
          console.log(err)
        })
    },
    postFollow(index) {
      const postId = this.Cpost[index].post_id
      addFollow({
        post_id: postId
      })
        .then(res => {
          console.log(res)
          this.Cpost[index].is_user_follow = !this.Cpost[index].is_user_follow
        })
        .catch(err => {
          console.log(err)
        })
    },
    play(index, index2) {
      this.Cpost[index].post_media_video[index2].is_show = true
      console.log(index, index2)
      const ref = `videoPlayer_${index}_${index2}`
      setTimeout(() => {
        this.$refs[ref][0].play()
      }, 300)
    },
    stop(index, index2) {
      const ref = `videoPlayer_${index}_${index2}`
      this.$refs[ref][0].player.pause()
    },
    showComment(index) {
      this.Cpost[index].post_comment_show = !this.Cpost[index].post_comment_show
    },
    more(index) {
      const ref = `more_${index}`
      const refMoreLink = `more_link_${index}`
      const refFewLink = `few_link_${index}`
      this.$refs[ref][0].className = ''
      this.$refs[refMoreLink][0].style = 'display:none;'
      this.$refs[refFewLink][0].style = 'display:block;'
    },
    few(index) {
      const ref = `more_${index}`
      const refMoreLink = `more_link_${index}`
      const refFewLink = `few_link_${index}`
      this.$refs[ref][0].className = 'more'
      this.$refs[refMoreLink][0].style = 'display:block;'
      this.$refs[refFewLink][0].style = 'display:none;'
    },
    setVideowWatermark(val) {
      this.handleVideoClick(val)
    },
    handleVideoClick(refValue) {
      // console.log(1, refValue)
      const video = this.$refs[`videoPlayer_${refValue}`][0]
      this.$refs[`fullscreen_${refValue}`][0].style = 'display:block;';
      if (!video.paused && !video.ended) {
        const i = this.$refs[`videoPlayerI_${refValue}`][0]
        console.log(i)
        i.style.display = 'block'
        console.log('视频正在播放')
        this.stopMovingText()
        const vm = this // 保存Vue实例的引用
        this.intervalId = setInterval(() => {
          vm.moveText(refValue) // 在箭头函数中调用moveText函数
        }, 3000)
      } else {
        console.log('视频未在播放')
      }
    },
    handleVideoFullScreenClick(refValue, currentTime = null) {
      const video = this.$refs['videoPlayer_full_screen']

      if (currentTime) {
        video.player.currentTime(currentTime);
      }

      if (!video.paused && !video.ended) {
        const i = this.$refs[refValue]
        console.log(i)
        i.style.display = 'block'
        console.log('视频正在播放')
        this.stopMovingText()
        const vm = this // 保存Vue实例的引用
        this.intervalId = setInterval(() => {
          vm.moveFullScreenText(refValue) // 在箭头函数中调用moveText函数
        }, 3000)
      } else {
        console.log('视频未在播放')
      }
    },
    moveText(refValue) {
      const i = this.$refs[`videoPlayerI_${refValue}`][0]
      i.style.top = `${Math.floor(Math.random() * 81)}%` // 生成 0 到 80 之间的整数
      i.style.left = `${Math.floor(Math.random() * 2) + 50}%` // 生成 50 到 56 之间的整数
    },
    moveFullScreenText(refValue) {
      const i = this.$refs[`${refValue}`]
      i.style.top = `${Math.floor(Math.random() * 70) + 10}%` // 生成 10 到 80 之间的整数
      i.style.left = `${Math.floor(Math.random() * 2) + 50}%` // 生成 50 到 56 之间的整数
    },
    stopMovingText() {
      clearInterval(this.intervalId) // 停止setInterval的执行
    },
    setFullScreenInfo(videoInfo, username, index, index2) {
      this.$refs[`videoPlayer_${index}_${index2}`][0].player.pause()
      const currentTime = this.$refs[`videoPlayer_${index}_${index2}`][0].player.currentTime()
      this.$refs[`videoPlayer_${index}_${index2}`][0].player.currentTime(0)
      this.curSelectInfo = {
        ...this.curSelectInfo,
        videoInfo,
        username,
        index,
        index2
      }
      this.showFullScreen = true;
      const vm = this;
      this.$nextTick(() => {
        vm.handleVideoFullScreenClick('videoPlayerI_full_screen', currentTime);
      })
    },
    videoOptions(r) {
      return {
        // width: 500,
        // height: 300,
        autoplay: false,
        controls: true,
        sources: [
          {
            src:
              r.media_video_url,
              type: 'video/mp4'
          }
        ],
        poster: r.media_url,
        userActions: {
          doubleClick: false
        },
        playbackRates: [1, 1.5, 2]
        // controlBar: {
        //   fullscreenToggle: false
        // }
      }
    },
    videoFullOptions(r) {
      return {
        ...this.videoOptions(r),
        autoplay: true
      }            
    },
    goDetailPage(val) {
      // if (this.$route.name === 'social.personaldetails') {\
      //   location.href = `/${val}`
      // } else {
      //   this.$router.push({path: `${val}`});
      // }
      location.href = `/${val}`
    }
  },
  beforeUnmount() {
    this.stopMovingText()
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style>
.video-js .vjs-big-play-button {
  margin-top: 0 !important;
  margin-left: 0 !important;
}
.home_page .video-js .vjs-fullscreen-control {
  visibility: hidden;
  opacity: 0;
}
.full_screen {
  position: absolute;
  bottom: -3px;
  right: 12px;
  color: #FFF;
  z-index: 3;
  cursor: pointer;
  display: none;
}
/* 移除视频播放器左上角的全屏按钮 */
video::-webkit-media-controls-start-playback-button {
  display: none !important;
}
/* 隐藏视频的全屏按钮 */
video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

video::-webkit-media-controls-enclosure {
  overflow: hidden !important;
}

.video-user-name {
  animation: moveText 1s infinite;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  z-index: 2000;
  color: #fff;
  opacity: 0.3;
  font-weight: 700;
  font-size: 12px;
  transform: scale(0.8) translateX(-50%) translateY(-20px);
  display: none;
}

@keyframes moveText {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(
      calc(100vw * var(--translate-x)),
      calc(100vh * var(--translate-y))
    );
  }

  100% {
    transform: translate(0, 0);
  }
}
.dropdown-item {
  color: #212529 !important;
}
.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:hover {
  background: transparent;
  color: var(--iq-primary) !important;
}
.img-right-icon {
  right: 30px;
}
.video-wrap {
  display: block;
  vertical-align: top;
  box-sizing: border-box;
  color: #fff;
  background-color: #000;
  position: relative;
  padding: 0;
  font-size: 10px;
  line-height: 1;
  font-weight: 400;
  font-style: normal;
  font-family: Arial, Helvetica, sans-serif;
  word-break: normal;
}
.vjs-big-play-img {
  position: static !important;
  vertical-align: top;
  max-height: calc(100vh - 400px);
}
.vjs-big-play-button {
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  /* bottom: 1%; */
  left: 50%;
  border: none;
  width: 48px;
  height: 48px;
  line-height: 48px;
  font-size: 32px;
  /* border-radius: 1000px; */
  background: rgba(0, 0, 0, 0.5);
  opacity: 0.7;
  color: #fefefe;
  transition: background-color 0.3s ease, opacity 0.3s ease;
  z-index: 1;
}
.more {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  box-sizing: border-box;
}
.el-radio-group {
  display: inline-flex;
  flex-wrap: nowrap;
  font-size: 0;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
}
video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
}

.modal_wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}
.video-js {
  height: 650px !important;
}
@media screen and ( max-width: 575px ) {
  .video-js {
    height: 660px !important;
  }
}
</style>

<style scoped>
.user-post {
  max-height: 600px;
  overflow: hidden;
}
.current_money {
  background: #e1f5fe;
  width: 40%;
  height: 40px;
  line-height: 40px;
  color: #1e343f;
  font-weight: bold;
  font-size: 16px;
  padding-left: 20px;
}
.total {
  color: #8c95a0;
  padding-right: 30px;
  font-size: 16px;
}
.unlock_post {
  color: #FFF;
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(80, 181, 255, .5);
  width: 230px;
  text-align: center;
  height: 44px;
  line-height: 44px;
  border-radius: 20px;
  cursor: pointer;
}
.photo_num {
  position: absolute;
  z-index: 2;
  top: 10px;
  right: 20px;
  background: rgba(0, 0, 0, .7);
  color: #FFF;
  text-align: center;
  height: 24px;
  line-height: 24px;
  border-radius: 12px;
  width: 50px;
}
.subscribe_btn:disabled {
  background: #777d74;
  opacity: 1 !important;
  border-color: #777d74;
}
.post_content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  min-height: 51px;
  max-height: 51px;
}
</style>
